<template>
  <component 
    :is="tag"
    v-on="$listeners" 
    class="rounded-lg p-2 px-4 text-center font-semibold hover:bg-opacity-80"
    :class="{
      'opacity-50 cursor-default': $attrs.disabled,
      'inline-block': anchor
    }"
  >
    <slot/>
  </component>
</template>

<script>
export default {
  props: {
    anchor: Boolean
  },
  computed: {
    tag() {
      return this.anchor ? 'a' : 'button'
    }
  }
}
</script>