<template>
  <fieldset>
    <legend class="mb-4 text-3xl font-semibold" v-if="legend">{{legend}}</legend>
    <slot/>
  </fieldset>
</template>

<script>
export default {
  props: {
    legend: {
      type: String,
      default: null,
    }
  }
}
</script>