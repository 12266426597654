<template>
  <div>
    <!-- <header class="bg-dark text-white px-4 brand-shadow flex flex-row items-center">
      <a href="https://islandluck.com">
        <img alt="Island Luck" src="../assets/logo-round-transparent.png" class="inline mr-4 h-10">
      </a>
    </header> -->
    <main class="flex justify-center">
      <slot/>
    </main>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
  }
}
</script>