<template>
  <div 
    v-if="alert"
    class="rounded border flex items-center flex-col sm:flex-row"
    :class="{
      'text-white border-brand-light': alert.type == 'success',
      'text-white border-red-500': alert.type == 'error',
      'text-white border-yellow-400': alert.type == 'warning',
    }"
  >
    <component :is="icon" :size="56" class="m-4 rounded-full p-1 w-14 flex-shrink-0" :class="{
      'bg-brand-light text-white': alert.type == 'success',
      'bg-red-400 text-dark': alert.type == 'error',
      'bg-yellow-400 text-dark': alert.type == 'warning',
    }"/>
    <div class="p-2 py-4">
      <h3 class="text-lg font-medium mb-1" v-text="alert.title"></h3>
      <p class="text-sm" v-html="alert.text"></p>
      <slot/>
    </div>
  </div>

</template>

<script>
import CheckCircle from '../icons/CheckCircle.vue'
import ExclamationTriangle from '../icons/ExclamationTriangle.vue'

export default {
  props: {
    alert: Object
  },
  computed: {
    icon() {
      switch(this.alert.type) {
        case 'success':
          return CheckCircle
        case 'warning': 
        case 'error': 
        default:
          return ExclamationTriangle
      }
    }
  }
}
</script>