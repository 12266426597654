<template>
  <label class="block mb-4" :class="{'input-invalid': invalid}">
    <div v-if="label">
      <div class="text-xl mb-2">
        {{ label }}
        <abbr v-if="required" title="Required" aria-label="Required">*</abbr>
      </div>
    </div>

    <!-- <p v-if="invalid" class="input-errors font-semibold">
      <ul>
        <li v-if="validation.required === false" v-text="$t('validation.required')" />
      </ul>
    </p> -->

    <slot>
      <div class="flex">
        <slot name="prefix">
          <span class="input-prefix" v-if="prefix">{{prefix}}</span>
        </slot>
        <slot name="input" v-bind="{invalid}">
          <select 
            v-if="type == 'select'" 
            v-bind="$attrs" 
            class="flex-auto rounded-lg" 
            :class="classes"
            @input="$emit('input',$event.target.value)" 
          >
            <option value=""></option>
            <option v-for="(option,i) in options" :value="option.value" :key="i" :selected="value === option.value">
              {{ option.text }}
            </option>
          </select>

          <input 
            v-else
            v-bind="$attrs" 
            class="flex-auto rounded-lg" 
            :class="classes"
            :type="type" 
            :value="value"
            @input="$emit('input',$event.target.value)" 
          >
        </slot>
        <slot name="suffix">
          <span class="input-suffix" v-if="suffix">{{suffix}}</span>
        </slot>
      </div>
    </slot>
  </label>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text'
    },
    value: [String,Number,Boolean],
    validation: {
      type: Object,
      default: null
    },
    options: {
      type: Array,
      default: Array
    },
    prefix: {
      type: String,
      default: null
    },
    suffix: {
      type: String,
      default: null
    }
  },
  computed: {
    invalid() {
      return this.validation && this.validation.$error
    },
    required() {
      return this.validation && 'required' in this.validation
    },
    classes() {
      return {
        'rounded-l-none': !!this.prefix || this.$slots.prefix?.length,
        'rounded-r-none': !!this.suffix || this.$slots.suffix?.length,
        'input-invalid': this.invalid
      }
    }
  }
}
</script>